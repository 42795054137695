<template>
  <div class="parallax" :style="style" >
    <h1 class="parallaximage-title">{{ title }}</h1>
    <h2 class="parallaximage-subtitle">{{ subtitle }}</h2>
  </div>
</template>

<script>
export default {
  props: ['imagePath', 'title', 'subtitle'],
  computed: {
    style () {
      return `background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${this.imagePath}); background-position: center; background-repeat: no-repeat; background-size: cover; background-attachment: fixed;`
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.parallax {
  @extend .titleshadow;
  height: 60vh;
  width: 100vw;
  color: $secondary;
  display: table-cell;
  vertical-align: middle;
}

.parallaximage-title {
  font-weight: bold;
  font-size: calc(1em + 2vw);
}

.parallaximage-subtitle {
  font-weight: 500;
  font-size: calc(1em + 1vw);
  margin-top: 3vh;
}
</style>

<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/programs/ra-aus-flight-instructor-course-800.png')" title="Grade 3 FLIGHT INSTRUCTOR COURSE" subtitle="Learning in a positive environment" />
    <div class="program-description">
      <h1>What is the Flight Instructor Course?</h1>
      <p>
        Our Grade 3 flight instructor course introduces you to flight training in a General Aviation environment.
      </p>
      <br>

      <h1>Requirements</h1>
      <div class="list-container">
        <ul class="program-list">
          <li>200 hours aeronautical experience</li>
          <li>100 aeronautical experience as Pilot in Command</li>
          <li>40 hours of dual in-flight instruction</li>
          <li>Private or Commercial Pilot Licence</li>
          <li>Spinning Flight Activity Endorsement</li>
          <li>Complete an approved course of training in principles and methods of instruction (PMI)</li>
        </ul>
      </div>

      <br>

    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
